import {
  init,
  captureException,
  captureMessage,
  BrowserTracing,
} from '@sentry/react';

export const initialiseSentry = () => {
  if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
    init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new BrowserTracing()],
      environment: process.env.REACT_APP_SST_STAGE || process.env.NODE_ENV,
      tracesSampleRate: 0,
    });
  }
};

export const logSentryError = (error: Error | string) => {
  captureException(error);
  if (process.env.NODE_ENV !== 'test') {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const logInfoToSentry = (message: string) => {
  captureMessage(message, 'info');
  if (process.env.NODE_ENV !== 'test') {
    // eslint-disable-next-line no-console
    console.log('[INFO] 🔵', message);
  }
};

export { default as SentryTestErrorComponent } from './SentryTestErrorComponent';
