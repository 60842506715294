import { IPatientPopulated } from 'app/suvera-api';

const baseURL: string = (() => {
  switch (process.env.REACT_APP_SST_STAGE) {
    case 'production': {
      return 'https://dashboard.suvera.org.uk';
    }
    default: {
      return 'https://demo--suvera-dashboard.netlify.app';
    }
  }
})();

export function linkToPatient(patient: IPatientPopulated) {
  return `${baseURL}/practice/${patient.practice._id}/patient/${patient._id}`;
}
